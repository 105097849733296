@import '~/styles/utils';

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 128rem calc(#{$side-padding} * 2) 174rem;
  position: relative;
  background: $color-white;
  z-index: 2;
}

.gradient {
  background: linear-gradient(180deg, #eaedf1 0%, rgba(234, 237, 241, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.contentContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 2;
  position: relative;
}

.heading {
  @include h2-m;
  margin-bottom: 24rem;
  text-align: center;
  max-width: 250rem;
  @include sm {
    max-width: none;
  }
  @include landscape {
    @include h2-d;
    max-width: none;
  }
}

.copy {
  @include body;
  margin-bottom: 28.8rem;
}

.ctaLink {
  text-align: center;
  margin-bottom: 28.8rem;

  @include landscape {
    margin-bottom: 50rem;
  }
}

.logosContainer {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 34rem;
  row-gap: 18rem;
  max-width: 450rem;
  padding: 0 $side-padding;

  @include landscape {
    grid-template-columns: repeat(6, 115rem);
    gap: 80rem;
    max-width: 100%;
  }
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
