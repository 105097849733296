@import '~/styles/utils';

.Intro {
  padding: 45rem 0;
  position: relative;
  overflow: hidden;

  @include landscape {
    padding: 90rem 0 140rem;
  }
}

.inner {
}

.gradientTop {
  pointer-events: none;
  position: absolute;
  left: 50%;
  background: radial-gradient(#66ffda 12.67%, transparent 70.95%);
  opacity: 0.2;
  z-index: 0;
  transform: translate(-50%, -60%);
  top: 0;
  width: 250vw;
  height: 250vw;

  @include landscape {
    width: 75vw;
    height: 75vw;
  }
}

.titleContainer,
.descriptionContainer,
.pointsListContainer {
  position: relative;
  z-index: 1;
}

.title {
  @include h1;
  text-align: center;
  max-width: 270rem;
  margin: 0 auto;

  @include sm {
    max-width: 1200rem;
  }
}

.descriptionContainer {
  padding: 30rem var(--side-padding) 55rem;

  @include landscape {
    padding: 100rem var(--side-padding);
  }
}

.description {
  @include large-body;
  max-width: 1530rem;
  margin: 0 auto;
  text-align: left;

  @include landscape {
    text-align: center;
  }
}

.pointsListContainer {
  padding: 0 var(--side-padding) 30rem;
  border-bottom: 1px solid rgb(var(--lavender));

  @include landscape {
    padding: 0 var(--side-padding) 100rem;
  }
}

.pointsList {
  padding: 0;
  list-style: none;
  max-width: 1800rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 35rem;

  @include landscape {
    display: grid;
    flex-direction: row;
    grid-template-columns: 0.4fr 0.3fr 0.3fr;
    gap: 114rem;
  }
}

.point {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10rem;

  @include landscape {
    gap: 35rem;
    flex-direction: row;
  }
}

.iconContainer {
  max-width: 50rem;
  min-width: 50rem;
  width: 50rem;
  height: 50rem;
  max-height: 50rem;
  min-height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(var(--ai-green));
  border-radius: 50%;

  @include landscape {
    max-width: 75rem;
    min-width: 75rem;
    width: 75rem;
    height: 75rem;
    max-height: 75rem;
    min-height: 75rem;
  }

  svg {
    width: 30rem;

    @include landscape {
      width: 40rem;
    }
  }
}

.text {
  @include large-body;
}
