@import '~/styles/utils';

.content {
  width: 100vw;
  height: 100svh;
  position: relative;
  z-index: 1;
}

.graidentBackgroundCanvas {
  opacity: $gradient-bg-opacity;
}

.gradientContainer {
  width: 100vw;
  height: 150vh;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.headlineWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headline {
  @include title;
  text-align: center;
  // opacity: 0;
  position: relative;

  @include landscape {
    transform: translateX(var(--headline-offset));
  }
}

.bottomCopy {
  text-align: center;
  position: absolute;
  bottom: 8vh;
  padding: 10 $side-padding;
  width: 100%;

  @include sm {
    bottom: 2vh;
  }

  @include md {
    bottom: 9vh;
  }
}

.bottomCopyContent {
  margin: auto;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  gap: 16rem;
  // & > * {
  //   transition: opacity $speed-metal $evil-ease, transform $speed-metal $evil-ease;
  // }
  // & :nth-child(1) {
  //   transition-delay: $speed-metal * 1.5;
  // }
  // & :nth-child(2) {
  //   transition-delay: $speed-metal * 1.65;
  // }
}

.subhead {
  @include h3;
  opacity: 0;
  transform: translateY(1em);
  font-size: 1.3em;

  @include sm {
    font-size: 3em;
  }
}

.copy {
  @include body;
  color: $color-gray;
  margin: auto;
  // max-width: 380rem;
  padding-top: 10rem;
  opacity: 0;
  transform: translateY(1em);

  @include sm {
    max-width: 560rem;
  }

  @include landscape {
    max-width: 1050rem;
    padding-top: 40rem;
  }
}
