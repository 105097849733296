@import '~/styles/utils';

$hero-height: 100svh;

.section {
  height: #{$hero-height * 1.5};
  // margin-bottom: -#{$hero-height}; // Negative margin used to allow for 100vh of overflow
  margin-bottom: 0; // Negative margin used to allow for 100vh of overflow
  width: 100%;
  position: relative;
}

// .blobContainer {
//   width: 100%;
//   height: $hero-height;
//   position: absolute;
//   top: 0;
//   left: 0;
//   &:after {
//     content: '';
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     background: transparent-color('white', 0.01);
//     z-index: 0;
//   }
//   // filter: blur(75rem);
//   // @include sm {
//   //   filter: blur(100rem);
//   // }
//   // @include landscape {
//   //   filter: blur(150rem);
//   // }
// }

.gradientContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.content {
  width: 100%;
  height: $hero-height;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
