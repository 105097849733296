@import '~/styles/utils';

.circleContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  mask-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0) 100%
  );
}

.circle {
  width: 78vmax;
  height: 78vmax;
  position: absolute;
  left: calc(50% - 39vmax);
  top: calc(50% - 39vmax);
}

.circleContent {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid $color-lavender;
  border-radius: 39vmax;

  &--dark {
    border: 1px solid transparent-color('lavender', 0.15);
  }
}
